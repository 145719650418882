// extracted by mini-css-extract-plugin
export var LabItem = "LabCard-module--LabItem--xCwHQ";
export var otherIsOpen = "LabCard-module--otherIsOpen--FCWvX";
export var LabCard = "LabCard-module--LabCard--9Pj0B";
export var reverse = "LabCard-module--reverse--Dw7VW";
export var isOpen = "LabCard-module--isOpen--G68ms";
export var video = "LabCard-module--video--Atmlk";
export var image = "LabCard-module--image--TVO2k";
export var main = "LabCard-module--main--U17lZ";
export var textBlock = "LabCard-module--textBlock--E9hri";
export var title = "LabCard-module--title--pPAVQ";
export var heading = "LabCard-module--heading--u6A0r";
export var blog = "LabCard-module--blog--aslpJ";
export var listTitle = "LabCard-module--listTitle--0n8X8";
export var description = "LabCard-module--description--rPuA8";
export var floatingDesktopText = "LabCard-module--floatingDesktopText--+caMn";
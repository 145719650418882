import React, { useEffect } from 'react'
import classNames from 'classnames/bind'

import { useThemeMode } from 'components/ui/layout/ThemeMode'

import Layout from 'layouts'
import SEO from 'components/seo'
import Container from 'components/ui/layout/Container'
import LabCard from 'components/ui/lab-card'
import Footer from 'components/ui/footer'

import useUIContext from 'context/ui'

import HeroText from 'components/ui/hero-text'
import LabIntro from 'components/ui/lab-intro'
import * as s from './LabPage.module.css'

const cn = classNames.bind(s)

const LabPage = ({ data }) => {
  const page = data?.prismicLab?.data
  if (!page) return null

  const isMobileMenuOpen = useUIContext(s => s.isMobileMenuOpen)
  const setHeaderTheme = useUIContext(s => s.setHeaderTheme)

  const setisLabTransitionRunning = useUIContext(state => state.setisLabTransitionRunning)
  const setShowBubbles = useUIContext(state => state.setShowBubbles)
  const showBubbles = useUIContext(state => state.showBubbles)
  const setShowBackgroundBubbles = useUIContext(state => state.setShowBackgroundBubbles)
  const isPageTransitionActive = useUIContext(state => state.isPageTransitionActive)
  const openLabProject = useUIContext(state => state.openLabProject)

  useThemeMode(isPageTransitionActive ? '' : 'dark')

  useEffect(() => {
    setisLabTransitionRunning(false)
    setShowBackgroundBubbles(true)
    return () => {
      setisLabTransitionRunning(false)
      setShowBubbles(0)
      setShowBackgroundBubbles(false)
    }
  }, [])

  useEffect(() => {
    !isPageTransitionActive ? setHeaderTheme('dark') : setHeaderTheme()
    return () => setHeaderTheme()
  }, [isPageTransitionActive])

  // show bubbles after mount
  useEffect(() => {
    let delay = showBubbles === 0 ? 2000 : 1000 // delay when leaving page
    let timer = setTimeout(() => setShowBubbles(1), delay)
    return () => {
      clearTimeout(timer)
    }
  }, [isPageTransitionActive, isMobileMenuOpen])

  const metaTitle = page?.page_meta_title
  const metaDescription = page?.page_meta_description
  const metaThumbnail = page?.page_meta_thumbnail

  return (
    <Layout isLabPage className={cn('LabPage')} fadeIn={false} background="#000" footer={false} theme={'dark'}>
      <SEO title={metaTitle} description={metaDescription} thumbnail={metaThumbnail} />
      <Container className={cn('hero')}>
        <HeroText text={page.title?.text} />
      </Container>

      {/* First 3 items */}
      {page.projects.slice(0, 3).map(({ project }, i) => (
        <Container className={cn('noPointerContainer')} key={`${project?.uid}`}>
          <div id={project?.uid}>
            <LabCard to={`#${project?.uid}`} reverse={!!(i % 2)} uid={project?.uid} index={i} {...project} />
          </div>
        </Container>
      ))}

      <Container className={cn('noPointerContainer')}>
        <LabIntro title={{ text: 'About the lab' }} text={page?.description} link={page?.description_link} />
      </Container>

      {/* The rest */}
      {page?.projects.slice(3).map(({ project }, i) => (
        <Container className={cn('noPointerContainer')} key={`${project?.uid}`}>
          <div id={project?.uid}>
            <LabCard to={`#${project?.uid}`} reverse={!!((3 + i) % 2)} uid={project?.uid} index={i + 3} {...project} />
          </div>
        </Container>
      ))}
      {/* TODO: must load more dynamically - take forever to create all projects if long list */}
      <Footer paintMode="spray" className={cn('footer', 'noPointerContainer', { hiddenFooter: !!openLabProject })} />
    </Layout>
  )
}

export default LabPage

import React, { useRef, useEffect, memo, useState } from 'react'
import { sRGBEncoding, LinearFilter, Texture } from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import { useSpring, useViewportScroll } from 'framer-motion'
import PropTypes from 'prop-types'
import lerp from '@14islands/lerp'

import useUIContext from 'context/ui'

import { play, pause } from 'lib/videoControls'
import { useScrollRig, ScrollScene } from '@14islands/r3f-scroll-rig'

import './LabImageMaterial'

const SPEED = 0.2 // idle blob speed

const LabImageMesh = ({
  inViewport,
  scene,
  image,
  video,
  el,
  scale,
  state: { bounds },
  layers,
  getScale = () => {},
  isMobile,
  index,
  transitionColor,
  isHovering,
  offset,
  reverse,
}) => {
  const mesh = useRef()
  const material = useRef()
  const local = useRef({ velocity: 0, scale: 1, minimized: false, imgOpacity: 0 }).current
  const [texture, setTexture] = useState()

  const { scrollY } = useViewportScroll()
  const { gl, size, viewport, camera, invalidate } = useThree()
  const { preloadScene } = useScrollRig()
  const pixelRatio = useThree(s => s.viewport.dpr)

  const isPageTransitionActive = useUIContext(state => state.isPageTransitionActive)
  const openLabProject = useUIContext(state => state.openLabProject)

  const blobPosX = useSpring(0, { stiffness: 2000, damping: 100, restDelta: 0.01, restSpeed: 0.01 })
  const blobPosY = useSpring(0, { stiffness: 2000, damping: 100, restDelta: 0.01, restSpeed: 0.01 })

  const imgEffect = useSpring(0, { stiffness: 30, damping: 50, restDelta: 0.001, restSpeed: 0.001 })
  const imgSize = useSpring(1, {
    stiffness: 40 + Math.random() * 20,
    damping: 5 + Math.random() * 2,
    restDelta: 0.001,
    restSpeed: 0.001,
  })

  const mouse = useRef({ x: 0, y: 0, vx: 0, vy: 0, showBlob: false }).current
  const mouseLocal = useRef({ x: 0, y: 0 }).current
  const isOpen = openLabProject === el
  const showVideo = isHovering || isOpen

  const onMouseMove = e => {
    if (isPageTransitionActive) return

    // Mouse scree position in px units
    mouse.y = -(e.clientY / size.height) * 2 + 1
    mouse.x = (e.clientX / size.width) * 2 - 1

    // TODO SHARE blob X/Y between all images instead of multiple springs and mouse listeners
    // TODO replace with lerp instead of spring?
    blobPosX.set(mouse.x)
    blobPosY.set(mouse.y)

    // Mouse screen position in viewport units
    mouse.vy = mouse.y * viewport.height * 0.5
    mouse.vx = mouse.x * viewport.width * 0.5

    // Mouse relative position over element ([-1, 1])
    mouseLocal.y = -((e.clientY - bounds.top + scrollY.get()) / bounds.height) * 2 + 1
    mouseLocal.x = ((e.clientX - bounds.left) / bounds.width) * 2 - 1
  }

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove)
    return () => {
      window.removeEventListener('mousemove', onMouseMove)
    }
  }, [viewport])

  // invalidate render loop on spring changes
  useEffect(() => blobPosX.onChange(() => isHovering && invalidate()), [])
  useEffect(() => blobPosY.onChange(() => isHovering && invalidate()), [])
  useEffect(() => imgEffect.onChange(invalidate), [])
  useEffect(() => imgSize.onChange(invalidate), [])

  const onVideoCanPlay = () => {
    if (!material.current || !video.current) return
    video.current.removeEventListener('loadeddata', onVideoCanPlay)

    var texture = new Texture(video.current)
    texture.anisotropy = gl.capabilities.getMaxAnisotropy()
    texture.generateMipmaps = false
    texture.magFilter = LinearFilter
    texture.minFilter = LinearFilter
    texture.encoding = sRGBEncoding
    material.current.video = texture

    // setTimeout(() => {
    //   gl.initTexture(texture)
    // }, index)
  }

  const onImageLoaded = () => {
    if (!material.current || !image.current) return
    image.current.removeEventListener('load', onImageLoaded)

    var texture = new Texture(image.current)
    texture.anisotropy = gl.capabilities.getMaxAnisotropy()
    texture.generateMipmaps = false
    texture.magFilter = LinearFilter
    texture.minFilter = LinearFilter
    texture.encoding = sRGBEncoding
    material.current.map = texture

    texture.needsUpdate = true
    setTexture(texture)
  }

  // Set loaded image as texture ap
  useEffect(() => {
    if (!texture) return
    // Upload texture to GPU so it's resized ahead of time
    const timer = setTimeout(() => {
      if (!scene || !camera) return
      material.current.map = texture
      preloadScene(scene, camera, null, () => {
        local.imgOpacity = 1
      })
    }, index)

    return () => clearTimeout(timer)
  }, [texture])

  useEffect(() => {
    if (video.current) {
      video.current.addEventListener('loadeddata', onVideoCanPlay)
      // check if video was cached and ready to play
      if (video.current.readyState > 3) {
        onVideoCanPlay()
      }
    }

    if (image.current) {
      image.current.addEventListener('load', onImageLoaded)
      // check if image was cached
      if (image.current.complete) {
        onImageLoaded()
      }
    }
  }, [])

  // dimensions
  useEffect(() => {
    material.current.planeSize = { width: bounds.width, height: bounds.height }
    material.current.resolution = { width: size.width, height: size.height }
  }, [size, bounds.width, bounds.height])

  const minimize = skipAnimation => {
    if (!local.minimized) {
      local.minimized = true
      local.imgOpacity = 0
      imgSize.set(0, false) // always instant when minimizing (uses lerp instead)
      if (skipAnimation) {
        material.current.imgOpacity = 0
        material.current.imgSize = 0
      }
    }
  }

  const normalize = skipAnimation => {
    if (local.minimized) {
      local.minimized = false
      local.imgOpacity = 1
      imgSize.set(1, !skipAnimation)
      if (skipAnimation) {
        material.current.imgOpacity = 1
        material.current.imgSize = 1
      }
    }
  }

  // OPEN - BIG VERSION
  useEffect(() => {
    // scale up and move to center
    if (isOpen) {
      local.scale = isMobile ? 1.7 : 1.3
      normalize()
    } else {
      local.scale = 1
      // hide other images while open
      // skipAnimation if outside viewport
      const skipAnimation = !bounds.inViewport
      if (openLabProject) {
        minimize(skipAnimation)
      } else {
        normalize(skipAnimation)
      }
    }
  }, [openLabProject])

  // HOVER effect
  useEffect(() => {
    let velocity = Math.abs(blobPosX.getVelocity()) + Math.abs(blobPosY.getVelocity())
    const mouseVelocity = Math.min(1.0, velocity * 0.5) || 0
    if (Math.abs(imgEffect.get()) < 2) {
      imgEffect.set(isHovering ? mouseVelocity : -mouseVelocity, false)
      imgEffect.set(0)
    }
    invalidate()
  }, [isHovering])

  useEffect(() => {
    if (video.current && showVideo) return play(video)
    if (video.current && !showVideo) return pause(video)
  }, [showVideo])

  // Render loop
  useFrame((_, delta) => {
    if (!material.current) return

    if (!bounds.inViewport) {
      return
    }

    // update video texture if playing
    if (!video.current?.paused && material.current.video) {
      material.current.video.needsUpdate = true
    }

    const velocity = Math.min(0.02, Math.abs(scrollY.getVelocity()) * 0.00006)
    local.velocity = lerp(local.velocity, velocity, 0.03, delta)
    material.current.time += delta * SPEED + local.velocity

    material.current.imgEffect = lerp(material.current.imgEffect, imgEffect.get(), 0.1, delta)
    material.current.imgOpacity = lerp(material.current.imgOpacity, local.imgOpacity, 0.05, delta)
    material.current.imgSize = local.minimized ? lerp(material.current.imgSize, 0, 0.1, delta) : imgSize.get()

    material.current.vmouse.x = lerp(material.current.vmouse.x, mouse.vx, 0.1, delta)
    material.current.vmouse.y = lerp(material.current.vmouse.y, mouse.vy, 0.1, delta)

    if (showVideo && material.current?.video) {
      material.current.showVideo = showVideo
    }

    mesh.current.scale.x = lerp(mesh.current.scale.x, local.scale * (getScale()?.x || 1), 0.1, delta)
    mesh.current.scale.y = lerp(mesh.current.scale.y, local.scale * (getScale()?.y || 1), 0.1, delta)

    let xOffset = 0
    if (offset === 0) xOffset = 0.07 * size.width
    if (offset === 14) xOffset = -0.07 * size.width
    const xDir = reverse ? -1 : 1
    if (isMobile) {
      xOffset = -0.5
      mesh.current.position.x = lerp(mesh.current.position.x, isOpen ? 0.25 * size.width * xDir : 0, 0.05, delta)
      mesh.current.position.y = lerp(mesh.current.position.y, isOpen ? -0.07 * size.height : 0, 0.05, delta)
    } else {
      mesh.current.position.x = lerp(mesh.current.position.x, isOpen ? xOffset * xDir : 0, 0.05, delta)
    }

    invalidate() // always render when mounted
  })

  return (
    <mesh ref={mesh} layers={layers}>
      <planeBufferGeometry attach="geometry" args={[scale.width, scale.height, 128, 128]} />
      <labImageMaterial transparent transitionColor={transitionColor} ref={material} pixelRatio={pixelRatio} />
    </mesh>
  )
}

LabImageMesh.propTypes = {
  ...ScrollScene.childPropTypes,
  getScale: PropTypes.func, // {x,y} to scale
  index: PropTypes.number,
  video: PropTypes.object,
  image: PropTypes.object,
  transitionColor: PropTypes.string,
}

export default memo(LabImageMesh)

import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from 'prismic'
import { prismicRepo } from '../../prismic-configuration'

import LabPage from 'views/lab/LabPage'

export const query = graphql`
  query LabPageQuery {
    prismicLab(uid: { eq: "lab" }, lang: { eq: "en-us" }) {
      _previewable
      data {
        page_meta_description
        page_meta_title
        page_meta_thumbnail {
          url
        }
        title {
          text
        }
        description {
          text
          richText
        }
        description_link {
          richText
          text
        }
        projects {
          project {
            uid
            document {
              ... on PrismicLabProject {
                data {
                  project_type
                  heading
                  title {
                    text
                  }
                  image {
                    alt
                    url
                    dimensions {
                      width
                      height
                    }
                  }
                  redirect_to {
                    url
                  }
                  transition_color
                  description {
                    text
                  }
                  video_mp4 {
                    url
                  }
                  video_webm {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(LabPage, [
  {
    repositoryName: prismicRepo,
    linkResolver,
  },
])

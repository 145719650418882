import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
// import { ScrollDomPortal } from '@14islands/r3f-scroll-rig'

import useUIContext from 'context/ui'

import SectionText from 'components/ui/section-text'
import WaterText from 'components/ui/water-text'
// import Portal from 'components/ui/portal'

import * as s from './LabIntro.module.css'
const cn = classNames.bind(s)

const LabIntro = ({ title, text, link }) => {
  const descriptionEl = useRef()
  const openLabProject = useUIContext(state => state.openLabProject)

  const hidden = !!openLabProject

  return (
    <div className={cn('description', { hidden })}>
      <SectionText
        ref={descriptionEl}
        title={title}
        text={text}
        link={link}
        underlineLink="hover"
        as={WaterText}
        textProps={{ hidden }}
      />
      {/* <Portal>
        <ScrollDomPortal el={descriptionEl} zIndex={1}>
          <SectionText title={title} text={text} link={link} as={WaterText} />
        </ScrollDomPortal>
      </Portal> */}
    </div>
  )
}

LabIntro.propTypes = {
  title: PropTypes.any,
  text: PropTypes.any,
  link: PropTypes.any,
}

export default LabIntro

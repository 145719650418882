import React, { memo, useRef, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'

import useUIContext from 'context/ui'
import requestIdleCallback from 'lib/requestIdleCallback'

import Cursor from 'components/ui/cursor'
import Clickable from 'components/ui/clickable'
import { useCanvas, ScrollScene } from '@14islands/r3f-scroll-rig'
import LabImageMesh from 'components/three/lab-image-mesh'
import Video from 'components/ui/video'
import Portal from 'components/ui/portal'
import WaterText from 'components/ui/water-text'

import config from 'config'

import * as s from './LabCard.module.css'

const cn = classNames.bind(s)

const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" fill="none" viewBox="0 0 52 52">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.296 25.574L12.36 37.508l2.131 2.131 11.935-11.934L38.36 39.639l2.131-2.13-11.934-11.935 11.508-11.508-2.131-2.13-11.508 11.507-11.508-11.508-2.131 2.131 11.508 11.508z"
      clipRule="evenodd"
    />
  </svg>
)

const LabCard = ({
  to,
  reverse,
  index,
  document: {
    data: { heading, project_type, title, image, video_mp4, video_webm, transition_color, redirect_to, description },
  },
}) => {
  const itemEl = useRef()
  const imgWrapperEl = useRef()
  const videoEl = useRef()
  const imgEl = useRef()
  const childrenEl = useRef()

  const [viewportWidth, viewportHeight] = useWindowSize()

  const setHideHeader = useUIContext(s => s.setHideHeader)
  const isMobile = useUIContext(s => s.isMobile)

  const setOpenLabProject = useUIContext(s => s.setOpenLabProject)
  const openLabProject = useUIContext(s => s.openLabProject)
  const setLabCTAPaused = useUIContext(s => s.setLabCTAPaused)
  const toggleNativeCursor = useUIContext(s => s.toggleNativeCursor)
  const isPageTransitionActive = useUIContext(state => state.isPageTransitionActive)

  const [isHoveringImage, setIsHoveringImage] = useState(false)
  const [isHoveringItem, setIsHoveringItem] = useState(false)

  const isOpen = openLabProject === imgWrapperEl
  const otherIsOpen = !!openLabProject && openLabProject !== imgWrapperEl
  const hasVideo = video_webm || video_mp4

  // tell GlobalCanvas to render our WebGl objects
  const updateBloblMesh = useCanvas(
    ({ key, isOpen, isMobile, isHovering, hasVirtualScrollbar }) => (
      <ScrollScene
        key={key}
        el={imgWrapperEl}
        lerpOffset={1 - (1 / (1 + (index % 2))) * 0.2}
        renderOrder={config.ORDER_LAB_CONTENT}
        scissor={false}
      >
        {props => (
          <LabImageMesh
            image={imgEl}
            video={videoEl}
            {...props}
            index={index}
            transitionColor={transition_color}
            isHovering={isHovering}
            isMobile={isMobile}
            offset={(index % 3) * 7}
            reverse={reverse}
          />
        )}
      </ScrollScene>
    ),
    [transition_color], // remount if prismic preview sends new props
  )

  // update webgl item positions when opened
  useEffect(() => {
    updateBloblMesh({ isOpen })
    setHideHeader(isOpen)
    setLabCTAPaused(isOpen)
    if (isOpen) {
      setTimeout(() => {
        const verticalAlign = isMobile ? 0.4 : 0.5
        const rect = itemEl.current.getBoundingClientRect()
        const targetY = window.pageYOffset + rect.top + rect.height * 0.5 - window.innerHeight * verticalAlign
        window.scrollTo(0, targetY, 0.05)
        // update URL - still lags slightly - but we need the deeplink i guess
        if (window.location.hash !== to) {
          window.history.replaceState({}, heading, to)
        }
      }, 200)
    }
  }, [isOpen])

  // close open project on touch move
  const touchMoveClose = useCallback(() => {
    requestIdleCallback(() => setOpenLabProject(false))
  }, [requestIdleCallback, setOpenLabProject])

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('touchmove', touchMoveClose)
      window.addEventListener('wheel', touchMoveClose)
    }
    return () => {
      window.removeEventListener('touchmove', touchMoveClose)
      window.removeEventListener('wheel', touchMoveClose)
    }
  }, [isOpen])

  useEffect(() => {
    toggleNativeCursor(isHoveringImage || (isOpen && isHoveringItem))
    requestIdleCallback(() => {
      updateBloblMesh({ isHovering: isHoveringImage, isMobile })
    })
  }, [isHoveringImage, isMobile, isOpen, isHoveringItem])

  // Close project if window is resized
  useEffect(() => {
    if (isOpen) setOpenLabProject(false)
  }, [viewportWidth, viewportHeight])

  // image from CMS should be square
  return (
    <>
      <div
        ref={itemEl}
        className={cn('LabItem', { otherIsOpen })}
        onMouseEnter={() => requestIdleCallback(() => setIsHoveringItem(true))}
        onMouseLeave={() => requestIdleCallback(() => setIsHoveringItem(false))}
      >
        <Clickable
          to={to}
          className={cn('LabCard', { reverse, [project_type]: true, isOpen })}
          onClick={e => {
            // click events stop videos and drop fps temporarily
            e.preventDefault()
            setOpenLabProject(isOpen ? null : imgWrapperEl)
          }}
          style={{ '--offset': `${(index % 3) * 7}vw` }}
        >
          <div
            ref={imgWrapperEl}
            className={cn('image')}
            style={{
              '--aspect-ratio': 1,
            }}
            onMouseEnter={() => requestIdleCallback(() => setIsHoveringImage(true))}
            onMouseLeave={() => requestIdleCallback(() => setIsHoveringImage(false))}
          >
            {image && (
              <img
                crossOrigin="anonymous"
                ref={imgEl}
                src={image.url}
                alt={image.alt}
                width={image.dimensions.width}
                height={image.dimensions.height}
              />
            )}
            <Cursor
              text={!isOpen ? 'View' : ''}
              icon={isOpen ? <CloseIcon /> : null}
              isEnabled={(isHoveringImage || (isOpen && isHoveringItem)) && !isPageTransitionActive}
              isDark={isOpen}
            />
            {hasVideo && (
              <Portal>
                <Video
                  className={cn('video')}
                  ref={videoEl}
                  invisible
                  webm={video_webm?.url}
                  mp4={video_mp4?.url}
                  preload="none"
                  autoplay={false}
                />
              </Portal>
            )}
          </div>
          <div className={cn('main', { reverse, isOpen })}>
            <div className={cn('textBlock', { reverse, isOpen, otherIsOpen })} ref={childrenEl}>
              <div className={cn('listTitle')}>
                {/* {heading && <div className={cn('heading')}>{heading}</div>} */}
                {heading && (
                  <WaterText
                    className={cn('heading')}
                    text={heading.toUpperCase()}
                    whiteSpace="nowrap"
                    hidden={!!openLabProject}
                  />
                )}
                {/* {title && <div className={cn('title', { [project_type]: true })}>{RichText.render(title)}</div>} */}
                {title?.text && (
                  <WaterText
                    className={cn('title', { [project_type]: true })}
                    text={title.text}
                    hidden={!!openLabProject}
                  />
                )}
              </div>
            </div>
          </div>
        </Clickable>

        <div
          className={cn('description', { reverse, isOpen })}
          onMouseEnter={() => requestIdleCallback(() => setIsHoveringItem(false))}
          onMouseLeave={() => requestIdleCallback(() => setIsHoveringItem(true))}
        >
          {heading && (
            <WaterText hidden={!isOpen} className={cn('heading')}>
              {heading}
            </WaterText>
          )}
          {title?.text && (
            <WaterText hidden={!isOpen} className={cn('title', { [project_type]: true })}>
              {title.text}
            </WaterText>
          )}
          {description?.text && <WaterText hidden={!isOpen}>{description.text}</WaterText>}
          {!!redirect_to && (
            <Clickable to={redirect_to?.url} hidden={!isOpen}>
              {project_type === 'blog' ? 'Read article on Medium' : 'Open experience'}
            </Clickable>
          )}
        </div>
      </div>
    </>
  )
}

LabCard.propTypes = {
  to: PropTypes.string,
  reverse: PropTypes.bool,
  title: PropTypes.array,
  index: PropTypes.number, // project index in list
  uid: PropTypes.string,
  document: {
    data: {
      heading: PropTypes.string,
      project_type: PropTypes.oneOf(['experiment', 'blog']),
      title: PropTypes.array,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
        dimensions: PropTypes.shape({
          width: PropTypes.number,
          height: PropTypes.number,
        }),
      }),
      video_mp4: PropTypes.shape({
        url: PropTypes.string,
      }),
      video_webm: PropTypes.shape({
        url: PropTypes.string,
      }),
      transition_color: PropTypes.string, // color to mix when crossfading image/video
      redirect_to: PropTypes.object,
      description: PropTypes.array,
    },
  },
}

export default memo(LabCard)
